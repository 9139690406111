import React, { useEffect, useRef, useState } from "react";
import {Route, Routes, Link, NavLink, useLocation } from 'react-router-dom'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Logo from './img/logo.svg';
import buy1 from './img/buy1.svg';
import buy2 from './img/buy2.svg';
import buy3 from './img/buy3.svg';

import { Home } from './pages/Home';
import { TestDrive } from './pages/TestDrive';
import { AboutCompany } from './pages/AboutCompany';
import { Delivery } from './pages/Delivery';
import { Showrooms } from './pages/Showrooms';
import { Thanks } from './pages/Thanks';
import { Modal } from './components/Modal';

import { NotFound } from './pages/NotFound'

function App() {

  const listAll = useRef()
  const [listF, setListF] = useState();


  useEffect(() => {
    if (listF !== undefined) {
      listF?.target?.nextElementSibling?.classList.toggle('active')
      listF?.target?.classList.toggle('active')
    }
  }, [listF])

  const setActiveLink = ({ isActive }) => isActive ? 'active' : '';

  let location = useLocation();
  const { pathname } = useLocation();

  const [modal, setModal] = useState({
    modal1: false
  })

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (openMenu === true) {
      document.body.classList.add('hide')
    } else {
      document.body.classList.remove('hide')
    }
  }, [openMenu])




  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);



  return (
    <>
      {pathname === '/test-drive' || pathname === '/' || pathname === '/about-company' || pathname === '/delivery' || pathname === '/showrooms' || pathname === '/thanks' ?
        <header className={location.pathname !== '/about-company' ? '' : 'nograd'}>
          <div className="main_wrapper">
            <div className="header">
              <div className="header__logo">
                <Link to="/"><img src={Logo} alt="logo" /></Link>
                <div className="sub__logo">Mfg.Co Japan</div>
              </div>
              <div className={openMenu !== true ? 'header__info' : 'header__info active'}>
                <nav className="heder__info_block">
                  <div className="heder__info_block_menu">
                    <ul>
                      <li><NavLink onClick={() => { setActiveLink(); setOpenMenu(false) }} to="/test-drive">Тест-драйв</NavLink></li>
                      <li><NavLink onClick={() => { setActiveLink(); setOpenMenu(false) }} to="/about-company">О компании</NavLink></li>
                      <li><NavLink onClick={() => { setActiveLink(); setOpenMenu(false) }} to="/delivery">Доставка и оплата</NavLink></li>
                      <li><NavLink onClick={() => { setActiveLink(); setOpenMenu(false) }} to="/showrooms">Шоурумы</NavLink></li>
                    </ul>
                  </div>
                  <div className="heder__info_block_contacts">
                    <a href="tel:+7 (800) 600-98-58" type='tel'>8 (800) 600-98-58</a>

                    <div className="heder__info_block_contacts__bottom">

                      <div className="header_menu__top">
                        <div className="header__logo">
                          <Link to="/"><img src={Logo} alt="logo" /></Link>
                          <div className="sub__logo">Mfg.Co Japan</div>
                        </div>
                        <div onClick={() => setOpenMenu(false)} className='menu-close'></div>
                      </div>
                      <h6>Контакты</h6>
                      <a href="tel:+7 (800) 600-98-58" type='tel'>8 (800) 600-98-58</a>
                      <span>Звонок бесплатный по РФ</span>
                      <div className="heder__info_block_contacts__bottom-social">
                        <a href="https://wa.me/79068669588"></a>
                        <a href="viber://chat?number=%2B79068669588"></a>
                        <a href=""></a>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>

              <div className="header__right_blocks">
                <a className="header__call_back-button" href="tel:+7(495)755-16-27" type='tel'>8 (495) 755-16-27</a>
                <button onClick={() => setModal({ ...modal, modal1: true })} className={location.pathname !== '/' ? 'header__call_back mainpage' : 'header__call_back'}>
                  Заказать звонок
                </button>
                <div onClick={() => setOpenMenu(true)} className="burger_menu">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </header>
        : ''}
      {modal.modal1 && <Modal title='Заказать звонок' etitle='Заявка на звонок' desc='modal1' onModalClose={() => setModal({ ...modal, modal1: false })} />}

      <div className={location.pathname !== '/' ? 'content' : 'content mainpage'}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/test-drive" element={<TestDrive />} />
          <Route path="/about-company" element={<AboutCompany />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/showrooms" element={<Showrooms />} />
          <Route path="/thanks" element={<Thanks />} />
        </Routes>
      </div>
      {pathname === '/test-drive' || pathname === '/' || pathname === '/about-company' || pathname === '/delivery' || pathname === '/showrooms' || pathname === '/thanks' ?
        <footer>
          <div className="main_wrapper">
            <div className="footer__blocks_top">
              <div className="footer__block">
                <div className="footer__block__logo">
                  <img src={Logo} alt="logo" />
                  <div className="sub__logo">Mfg.Co Japan</div>
                </div>
                {/* <div className="footer__block__social">
                <a href="https://wa.me/79068669588"></a>
                <a href="viber://chat?number=%2B79068669588"></a>
                <a href=""></a>
              </div> */}

              </div>
              <div ref={listAll} onClick={(listAll) => setListF(listAll)} className="footer__block">
                <h6>Компания</h6>
                <div className='footer__block-list'>

                  <Link to="/test-drive">Тест-драйв</Link>
                  <Link to="/about-company">О компании</Link>
                  <Link to="/delivery">Доставка и оплата</Link>
                  <Link to="/showrooms">Шоурумы</Link>

                </div>
              </div>
              <div ref={listAll} onClick={(listAll) => setListF(listAll)} className="footer__block">
                <h6> <Link to="/showrooms">Выставочные залы</Link></h6>
                <div className='footer__block-list'>
                  <p>Москва (Центр)</p>
                  <p>Москва (МКАД)</p>
                  <p>Санкт-Петербург</p>
                  <p>Самара</p>
                  <p>Оренбург</p>
                  <p>Уфа</p>
                  <p>Екатеринбург</p>
                  <p>Челябинск</p>
                  <p>Магнитогорск</p>
                  <p>Тюмень</p>
                  <p>Сургут и др.</p>
                </div>
              </div>
              <div className="footer__block">
                <h6>Контакты</h6>
                <div className="footer__block__contact">
                  <div className="footer__block__contact-phone">
                    <span>Есть вопросы? Звоните!</span>
                    <a href="tel:8 (800) 600-98-58">8 (800) 600-98-58</a>
                    <p>Звонок бесплатный по РФ</p>

                    <div className="block__social">
                      <span>Или пишитe:</span>
                      <div className="footer__block__social">
                        <a href="https://wa.me/79068669588"></a>
                        <a href="viber://chat?number=%2B79068669588"></a>
                        <a href=""></a>
                      </div>
                    </div>
                  </div>
                  <span className="footer__block__contact-email">
                    <a href="mailto:support@fujiiryoki.ru">support@fujiiryoki.ru</a>
                  </span>
                  <button onClick={() => setModal({ ...modal, modal1: true })} >Заказать звонок</button>
                </div>
              </div>
            </div>

            <div className="footer__blocks_bottom">
              <p>© 2021 Fujiiryoki. Все права защищены.</p>
              <a target="_blank" href="./doc/Privacy_Policy.pdf">Политика конфиденциальности</a>
              <div className='buy'>
                <img src={buy1} alt="" />
                <img src={buy2} alt="" />
                <img src={buy3} alt="" />
              </div>
            </div>
          </div>
        </footer>
        : ''}
    </>
  );
}

export default App;

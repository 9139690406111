import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import '../scss/aboutcompany.scss';
import emailjs from 'emailjs-com';

import AboutcompanyText from '../img/aboutcompany/aboutcompany-text.svg';
import AboutcompanyPhoto from '../img/aboutcompany/aboutcompany-photo.png';

import AboutcompanyMan from '../img/aboutcompany/aboutcompany-photo-man.png';
import historymassagerOld from '../img/aboutcompany/history-massager-old.png';

import history_massager_old1 from '../img/aboutcompany/history-massager-old-1.png';
import history_massager_old2 from '../img/aboutcompany/history-massager-old-2.png';
import history_massager_old3 from '../img/aboutcompany/history-massager-old-3.png';
import history_massager_old4 from '../img/aboutcompany/history-massager-old-4.png';
import meisters from '../img/aboutcompany/meister.png';
import meister1 from '../img/aboutcompany/meister1.png';
import meister2 from '../img/aboutcompany/meister2.png';
import From from '../img/test-drive/form.png';


// Import Swiper styles

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



// import Swiper core and required modules
import SwiperCore, {
    Scrollbar, Navigation
} from 'swiper';
import NumberFormat from "react-number-format";

// install Swiper modules
SwiperCore.use([Scrollbar, Navigation]);



const arr = [
    { year: 1954, title: 'Самое первое массажное кресло Fujiiryoki', desc: 'Fujiiryoki создаёт первое в мире массажное кресло, пока из дерева. Учредитель компании Нобуо Фудзимото прежде, чем создать окончательный коммерческий вариант, сделал бесчисленное количество прототипов, используя отходы деревообработки.', img: '../img/courusel/courusel1.png' },
    { year: 1965, title: 'Автоматическое массажное кресло Fujiiryoki L-ll', desc: 'Массаж проводился четырьмя массажными роликами. Появилась возможность регулировать высоту с помощью ручки сбоку, а также силу массажа при помощи переключателя.', img: '../img/courusel/courusel2.png' },
    { year: 1975, title: 'Массажное кресло FM-52', desc: 'Новая инновационная система сцепления Fujiiryoki сделала возможным реализацию новых автоматических функций, таких как разминающий массаж и постукивание.', img: '../img/courusel/courusel3.png' },
    { year: 1987, title: 'Роликовой кресло KIT-10DX', desc: 'В этом массажном кресле уже используется 8 прокатывающихся роликов.Кроме того, был внесен ряд улучшений в конструкцию и усовершенствованных функций, таких как пульт дистанционного управления роликами.', img: '../img/courusel/courusel4.png' },
    { year: 1995, title: 'Массажное кресло с воздушными подушками Royal Chair MC-133', desc: 'Это первое массажное кресло, в котором использовались попеременно надувающиеся воздушные подушки. Подушки массировали бедра и ноги.', img: '../img/courusel/courusel5.png' },
    { year: 2000, title: 'Массажное кресло Air Solution AS-777', desc: 'Это стало первым массажным креслом, в конструкции которого объединились сразу две массажных технологии – ролики и воздушные подушки.', img: '../img/courusel/courusel6.png' },
    { year: 2001, title: 'Массажное кресло Cyber-relax AS-001', desc: 'В этой модели была впервые применена такая функция как 3D- Point Navigation System, которая подстраивается под форму тела сидящего в массажном кресле человека и автоматически выбирает лучшую программу массажа.', img: '../img/courusel/courusel7.png' },
    { year: 2002, title: 'Массажное кресло Cyber-relax AS-003', desc: 'Кроме нового дизайна, в этой модели была добавлена отдельная программа массажа ног воздушными подушками «Leg Stretch» и «Cross Press».', img: '../img/courusel/courusel8.png' },
    { year: 2004, title: 'Массажное кресло Cyber-relax AS-800', desc: 'Это первое массажное кресло, которое было оснащено функцией для массажа плеч и стоп, что позволило массировать практически всё тело, от затылка до ступней.', img: '../img/courusel/courusel9.png' },
    { year: 2010, title: 'Массажное кресло Ken Okuyama', desc: 'Специальная модель массажного кресла, дизайн которой разработал всемирно известный промышленный дизайнер Кен Окуяма (Ken Okuyama).', img: '../img/courusel/courusel10.png' },
    { year: 2012, title: 'Массажное кресло Cyber-relaxas 850 (EC-3800)', desc: 'Флагманская модель массажного кресла Fujiiryoki, в которой установлена усовершенствованная система «Kiwami-mecha 4D».', img: '../img/courusel/courusel11.png' },
    { year: 2015, title: 'Флагман традиций и инноваций нового поколения Cyber ​​Relax Massage Chair AS-1000', desc: 'Для снятия зажимов в мышцах, кресло оснащено механическим блоком Deep Pole Mecha PRO для глубокого и деликатного массажа. Кроме того, появилась возможность регулировать интенсивность и скорость массажа.', img: '../img/courusel/courusel12.png' },
    { year: 2017, title: 'Объединение всех передовых технологий Cyber ​​Relax Massage Chair AS-1100', desc: 'Унаследовав передовую технологию массажа, которую мы культивировали до сих пор, мы усилили 12 типов 7-минутного мини-автоматического курса и массажа ног, который специализируется на областях, где вы чувствуете скованность и усталость.', img: '../img/courusel/courusel13.png' },
    { year: 2019, title: 'Массаж с искусственным интеллектом Cyber ​​Relax Massage Chair JP-2000', desc: 'Внедрена технология искусственного интеллекта. Благодаря новой разработке механизма 5D-AI и двойного сканирования реализован массаж приближенный к реальному, и автоматически подстраивается  </br>к особенностям тела каждого человека. ', img: '../img/courusel/courusel14.png' }
];

export const AboutCompany = () => {
    const [chekF, setChekF] = useState(true)
    const [chekB, setChekB] = useState();
    let navigate = useNavigate();


    const sendEmail = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_8p7tccp', 'template_q9nvppd', e.target, 'user_h8FptbxZL1IEIHpQZnLeu')
        navigate("/thanks")
        // window.location.href = '/thanks';
    }



    const [nFrom, setNForm] = useState();
    const [pFrom, setPForm] = useState();

    useEffect(() => {
        if (chekF === true && nFrom?.target.value.length > 2 && pFrom?.target.value.split('_').join('').split('-').join('').split(' ').join('').length > 13) {
            setChekB(false)
        } else {
            setChekB(true)
        }
    }, [nFrom, pFrom, chekF])

    const [numberSlider, setNumberSlider] = useState(0)

    /* Анимация */
    useEffect(() => {
        readyHandler()
    }, [])

    const readyHandler = () => {
        const animation_active_opacity = document.querySelectorAll('.animation_active-opacity');
        const animation_active_bottom = document.querySelectorAll('.animation_active-bottom');

        setTimeout(() => {
            for (let i = 0; i < animation_active_opacity.length; i++) {
                animation_active_opacity[i].classList.remove('animation_active-opacity');
            }
            for (let i = 0; i < animation_active_bottom.length; i++) {
                animation_active_bottom[i].classList.remove('animation_active-bottom');
            }
        }, 500);
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return function () {
            document.removeEventListener('scroll', scrollHandler)
        };
    }, [])

    const scrollHandler = () => {
        setTimeout(() => {
            const arrAnimation_active_bottom = document.querySelectorAll('.animation_active-bottom-scroll');
            const arrAnimation_active_right = document.querySelectorAll('.animation_active-right-scroll');
            const arrAnimation_active_opacity = document.querySelectorAll('.animation_active-opacity-scroll');
            const arrAnimation_active_number = document.querySelectorAll('.animation_active-number-scroll');


            for (let i = 0; i < arrAnimation_active_bottom.length; i++) {
                var elementBoundary = arrAnimation_active_bottom[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_bottom[i].classList.remove('animation_active-bottom-scroll');
                }
            }
            for (let i = 0; i < arrAnimation_active_right.length; i++) {
                var elementBoundary = arrAnimation_active_right[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_right[i].classList.remove('animation_active-right-scroll');
                }
            }


            for (let i = 0; i < arrAnimation_active_opacity.length; i++) {
                var elementBoundary = arrAnimation_active_opacity[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_opacity[i].classList.remove('animation_active-opacity-scroll');
                }
            }

            for (let i = 0; i < arrAnimation_active_number.length; i++) {
                var elementBoundary = arrAnimation_active_number[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_number.forEach(counter => {
                        let count = 0;
                        let count1000 = 1600;
                        let count100 = 350;

                        const updateCounter = () => {
                            const countTarget = parseInt(counter.getAttribute('data-counttarget'));
                            if (countTarget < 2000 && countTarget > 1601) {
                                count = count + count1000
                                count++;
                                count1000 = 1;
                            } else if (countTarget < 1600 && countTarget > 50) {
                                count = count + count100
                                count++;
                                count100 = 1
                            } else {
                                count++;
                            }
                            if (count < 40) {
                                counter.innerHTML = count;
                                arrAnimation_active_number[i].classList.remove('animation_active-number-scroll')
                                setTimeout(updateCounter, 15);
                            }
                            else if (count < countTarget) {
                                counter.innerHTML = count;
                                arrAnimation_active_number[i].classList.remove('animation_active-number-scroll')
                                setTimeout(updateCounter, 1);
                            } else {
                                counter.innerHTML = countTarget;
                            }
                        };
                        updateCounter();
                    });
                }
            }
        });
    }


    return (
        <div className="wrapper__aboutcompany">
            <div className="main__block_aboutcompany animation_active-bottom">
                <div className='animation_active-opacity'>
                    <img src={AboutcompanyText} alt="" />
                    <span>SINCE 1954 IN JAPAN</span>
                </div>
            </div>
            <div className="main_wrapper">
                <div className="aboutcompany__blocks">

                    <div className="aboutcompany__block">
                        <h1 className='animation_active-opacity-scroll'>О компании</h1>
                        <p className='animation_active-bottom-scroll'>Японская компания <span> FUJI MEDICAL INSTRUMENTS </span>- первопроходец и лидер индустрии массажных кресел. Компания была основана в Японии в 1954 году и занимает 30% мирового рынка массажных кресел, а продукция пользуется спросом более чем в двадцати странах мира. </p>
                    </div>
                    <div className="aboutcompany__block">
                        <div>
                            <img src={AboutcompanyPhoto} alt="" className='animation_active-opacity-scroll' />
                        </div>
                    </div>
                </div>
                <div className="short_info__blocks">
                    <div className="short_info__block">
                        <span data-counttarget="1954" className='animation_active-number-scroll'></span>
                        <p>Год основания</p>
                    </div>
                    <div className="short_info__block">
                        <span data-counttarget="1965" className='animation_active-number-scroll'></span>
                        <p>Год  учреждения</p>
                    </div>
                    <div className="short_info__block">
                        <span>¥<span data-counttarget="30" className='animation_active-number-scroll'></span><span>млн</span></span>
                        <p>Капитал</p>
                    </div>
                </div>

                <div className="history_company">
                    <div className="history_company__blocks">
                        <div className="history_company__block">
                            <div>
                                <img className='animation_active-opacity-scroll' src={AboutcompanyMan} alt="" />
                            </div>
                            <h6 className='animation_active-opacity-scroll'>Фудзимото Нобуо</h6>
                            <p className='animation_active-bottom-scroll'>Изобретатель массажного кресла <br />и основатель компании «Фудзи Ирёки»</p>
                        </div>
                        <div className="history_company__block">
                            <h2 className='animation_active-opacity-scroll'>История развития компании</h2>
                            <p className='animation_active-bottom-scroll'>Компания <span> Фуджириоки (Fujiiryoki)</span> в 1954 году представила первое <br />в мире кресло с электроприводом и массажным механизмом. Изобрел кресло основатель компании Фуджириоки Нобуо Фуджимото (Nobuo Fujimoto). Первый прототип он собрал прямо <br />у себя дома из подручных материалов и старой мебели.</p>
                            <p className='animation_active-bottom-scroll'>Именно эта дата послужила стартовым толчком для развития индустрии массажных кресел. С тех пор и по сей день <span> FUJIIRYOKI </span><br />— пионер в разработке новейших массажных техник и функций, применяемых в области создания массажных кресел.<br /> Техника Fujiiryoki получила одобрение у самых требовательных в мире органов по контролю здравоохранения — в США и Японии.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="history_massager">
                <div className="main_wrapper">
                    <div className="history_massager__blocks">
                        <div className="history_massager__block">
                            <h3 className='animation_active-opacity-scroll'>Первое в мире массажное кресло</h3>
                            <p className='animation_active-bottom-scroll'>Первое массажное кресло не отличалось «королевским дизайном» и представляло собой деревянный стул <br />с высокой прямой спинкой, в которую было вмонтировано два круглых массажных ролика. Ролики приводились в движение электромотором и примыкали <br />к спине, обивка в кресле не предполагалась. </p>
                            <p className='animation_active-bottom-scroll'>Сила воздействия и направление движения роликов не регулировались. Однако при всей своей угловатости первое массажное кресло было сделано на совесть <br />и до сих пор находится в рабочем состоянии.</p>
                        </div>
                        <div className="history_massager__block">
                            <img src={historymassagerOld} alt="" className='animation_active-right-scroll' />
                        </div>
                    </div>
                    <div className="histor_massager__bs">
                        <div className="histor_massager__b animation_active-opacity-scroll">
                            <img src={history_massager_old1} alt="" />
                            <span>Кнопка ВКЛ/ВЫКЛ <br />с левой стороны</span>
                        </div>
                        <div className="histor_massager__b animation_active-opacity-scroll">
                            <img src={history_massager_old2} alt="" />
                            <span>Корпус кресла <br />выполнен из дерева</span>
                        </div>
                        <div className="histor_massager__b animation_active-opacity-scroll">
                            <img src={history_massager_old3} alt="" />
                            <span>Внутренний механизм</span>
                        </div>
                        <div className="histor_massager__b animation_active-opacity-scroll">
                            <img src={history_massager_old4} alt="" />
                            <span>Ручка для перемещения <br />роликов вверх и вниз </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main_wrapper">
                <div className="history_swiper_massager__wrapper">

                    <h5 className='animation_active-opacity-scroll'> <span> Эволюция</span> массажных кресел</h5>

                    <Swiper
                        onSlideChange={(e) => setNumberSlider(e.realIndex)}
                        width={100}
                        slidesPerView={'auto'}
                        slidesOffsetBefore={50}
                        navigation={{
                            prevEl: '.swiper-prev',
                            nextEl: '.swiper-next',
                        }}
                        className="mySwiper">

                        {arr.map((item, i) => {
                            return (
                                <SwiperSlide value={i} key={i}>{item.year}</SwiperSlide>

                            )
                        })
                        }
                    </Swiper>

                    <div className="history_swiper_massager">
                        <div className="history_swiper_massager__block">
                            <div className="swiper-prev" onClick={numberSlider === 0 ? () => '' : () => setNumberSlider(numberSlider - 1)}></div>
                            <div className={numberSlider >= arr.length - 1 ? 'swiper-button-disabled' : 'swiper-next'} onClick={numberSlider === arr.length - 1 ? () => '' : () => setNumberSlider(numberSlider + 1)}></div>
                        </div>
                        <div className="history_swiper_massager__block">

                            <div className="history_massager__bs" >
                                <div className="history_massager__b">
                                    <h4>{arr[numberSlider].title}</h4>
                                    <p>{arr[numberSlider].desc}</p>
                                </div>
                                <div className="history_massager__b">
                                    <img src={arr[numberSlider].img} alt="" />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="massage_masters-top">
                    <h5 className='animation_active-opacity-scroll'> <span>Мастера </span> массажных кресел</h5>
                    <div>
                        <img src={meisters} alt="" className='animation_active-opacity-scroll' />
                        <span className='animation_active-bottom-scroll'>В Fuji Medical Instruments есть два сотрудника с титулом «Мастера массажного кресла», которые оценивают и регулируют  степень комфорта массажа. </span>
                        <p className='animation_active-bottom-scroll'>Все массажные кресла были реализованы с разрешения мастеров, <br />и, будучи главными менеджерами по  разработке продуктов, <br />они лично тестируют массажные кресла по различным параметрам, <br />такие как сила, угол, скорость, настройка.</p>
                    </div>
                </div>

                <div className="massage_masters-bottom">
                    <div className="massage_masters-bottom__block">
                        <h5 className='animation_active-bottom-scroll'>Мицуаки Фудзиширо </h5>
                        <p className='animation_active-bottom-scroll'>Работает в компании <span> Fuji Medical Instrument Co., Ltd. </span>с 1983 года. С момента прихода в компанию он уделял большое внимание производству и продолжает производить привлекательные массажные кресла для всего мира. </p>
                        <p className='animation_active-bottom-scroll'>Также он разрабатывает техники, которые могут быть выполнены только с помощью массажного кресла. Вместе со своей собственной командой он каждый день работает над развитием и улучшением массажных кресел.</p>
                    </div>
                    <div className="massage_masters-bottom__block"> <img src={meister1} alt="" className='animation_active-opacity-scroll' /></div>

                </div>


                <div className="massage_masters-bottom bottom">
                    <div className="massage_masters-bottom__block"> <img src={meister2} alt="" className='animation_active-opacity-scroll' /></div>
                    <div className="massage_masters-bottom__block">
                        <h5 className='animation_active-bottom-scroll'>Кентаро Оиде</h5>
                        <p className='animation_active-bottom-scroll'>В 2004 году присоединился к <span> Fuji Medical Instrument Co., Ltd.</span> Являясь краеугольным камнем команды,<br />он внес большой вклад в эволюцию полярной механики в последние годы, улучшил работу компрессии и создал огромное количество разнообразных автоматических программ.</p>
                    </div>
                </div>

                <div className="block_test_drive-from">
                    <div className="block_test_drive-from__block">
                        <img src={From} alt="" />
                    </div>
                    <form className="block_test_drive-from__block" method="get" onsubmit="yaCounter86637692.reachGoal('ORDER'); return true;" onSubmit={sendEmail}>
                        <h6>Оставьте заявку</h6>
                        <p>И мы свяжемся с вами и ответим <br />на все ваши вопросы</p>
                        <input className='form-text' type="text" onChange={(e) => setNForm(e)} name='name' placeholder='Ваше имя' />
                        <NumberFormat className='form-text' placeholder='Телефон' format="+7 (###) ###-##-###" onMouseEnter={(e) => e.target.placeholder = '+7 (___) ___-__-___'} onMouseLeave={(e) => e.target.placeholder = 'Телефон'} onChange={(e) => setPForm(e)} type="text" name='phone' mask="_" />
                        <div >
                            <input className="call_back-checkbox" type="checkbox" checked={chekF} onChange={() => setChekF(!chekF)} name='title' value='Заявка на звонок' />
                            <p>Я ознакомлен и согласен <a href="./doc/Privacy_Policy.pdf">с условиями политики
                                обработки персональных данных и конфиденциальности</a></p>
                        </div>
                        <button className="form-button"  disabled={chekB} type='submit' >Отправить заявку</button>
                    </form>
                </div>
            </div>
        </div >
    )
}

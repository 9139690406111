import React, { useEffect, useRef, useState } from "react";
import emailjs from 'emailjs-com';
import '../scss/test-drive.scss';

import { useNavigate } from "react-router-dom";
import TestDriveMassager from '../img/test-drive/testdrive-massager.png';
import TestDriveMap from '../img/test-drive/testdrive-map.png';
import TestdriveHouseIcon from '../img/test-drive/testdrive-house-icon.svg';
import TestdriveShowroomsIcon from '../img/test-drive/showrooms.svg';
import Result from '../img/test-drive/result.png';
import Form from '../img/test-drive/form.png';
import NumberFormat from "react-number-format";


export const TestDrive = () => {

    const choice = useRef();
    const choice2 = useRef();
    const choiceMain = useRef();
    const navigate = useNavigate();
    const [chekF, setChekF] = useState(true)
    const [chText, setChText] = useState('Домой или в офис');
    const [chekB, setChekB] = useState();
    const [chekB1, setChekB1] = useState();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_8p7tccp', 'template_q9nvppd', e.target, 'user_h8FptbxZL1IEIHpQZnLeu')
        navigate("/thanks");
        // window.location.href = '/thanks';
    }

    const [nFrom, setNForm] = useState();
    const [pFrom, setPForm] = useState();
    const [nFrom1, setN1Form] = useState();
    const [pFrom1, setP1Form] = useState();

    useEffect(() => {
        if (chekF === true && nFrom?.target.value.length > 2 && pFrom?.target.value.split('_').join('').split('-').join('').split(' ').join('').length > 13) {
            setChekB(false)
        } else {
            setChekB(true)
        }
        if (chekF === true && nFrom1?.target.value.length > 2 && pFrom1?.target.value.split('_').join('').split('-').join('').split(' ').join('').length > 13) {
            setChekB1(false)
        } else {
            setChekB1(true)
        }
    }, [nFrom, pFrom, chekF, nFrom1, pFrom1])

    useEffect(() => {
        if (choiceMain !== undefined) {
            document.addEventListener('scroll', scrollHandler)
            return function () {
                document.removeEventListener('scroll', scrollHandler)
            };
        }
    }, [])


    const scrollHandler = () => {

        setTimeout(() => {
            const arrAnimation_active_bottom = document.querySelectorAll('.animation_active-bottom-scroll');
            const arrAnimation_active_right = document.querySelectorAll('.animation_active-right-scroll');
            const arrAnimation_active_opacity = document.querySelectorAll('.animation_active-opacity-scroll');


            for (let i = 0; i < arrAnimation_active_bottom.length; i++) {
                var elementBoundary = arrAnimation_active_bottom[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_bottom[i].classList.remove('animation_active-bottom-scroll');
                }
            }
            for (let i = 0; i < arrAnimation_active_right.length; i++) {
                var elementBoundary = arrAnimation_active_right[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_right[i].classList.remove('animation_active-right-scroll');
                }
            }

            for (let i = 0; i < arrAnimation_active_opacity.length; i++) {
                var elementBoundary = arrAnimation_active_opacity[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_opacity[i].classList.remove('animation_active-opacity-scroll');
                }
            }
        });



        if (choice?.current?.getBoundingClientRect().top < 100) {
            choiceMain?.current.classList.add('active');
        }
        if (choice?.current?.getBoundingClientRect().top > 100) {
            choiceMain?.current.classList.remove('active');
        }
        if (choice2?.current?.getBoundingClientRect().top < 150) {
            return (choiceMain?.current.childNodes[0].childNodes[2].classList.add('active'),
                setChText('Наши шоурумы'));
        }
        if (choice2?.current?.getBoundingClientRect().top > 150) {
            return (choiceMain?.current.childNodes[0].childNodes[2].classList.remove('active'),
                setChText('Домой или в офис'));
        }
    }


    const Scroll = (param) => {
        if (param.current.classList.contains('showrooms') !== true) {
            window.scrollBy({
                top: param.current.getBoundingClientRect().top,
                behavior: "smooth"
            })
        } else {
            window.scrollBy({
                top: param.current.getBoundingClientRect().top - 95,
                behavior: "smooth"
            })
        }
    }



    /* Анимация */
    useEffect(() => {
        readyHandler()
    }, [])

    const readyHandler = () => {
        const animation_active_opacity = document.querySelectorAll('.animation_active-opacity');
        const animation_active_bottom = document.querySelectorAll('.animation_active-bottom');

        setTimeout(() => {
            for (let i = 0; i < animation_active_opacity.length; i++) {
                animation_active_opacity[i].classList.remove('animation_active-opacity');
            }
            for (let i = 0; i < animation_active_bottom.length; i++) {
                animation_active_bottom[i].classList.remove('animation_active-bottom');
            }
        }, 500);
    }








    return (
        <div className="wrapper__test_drive">
            <div className="main__block_test_drive animation_active-opacity">
                <img src={TestDriveMassager} className='animation_active-opacity' alt="" />
            </div>
            <div className="main_wrapper">
                <div className="description__block_test_drive">
                    <div className="description__test_drive_blocks">
                        <div className="description__test_drive_block">
                            <h6 className='animation_active-bottom-scroll'>Протестируйте <br />массажное кресло <br /><span> там, где вам удобно!</span></h6>
                            <p className='animation_active-opacity-scroll'>Не нужно никуда ехать. Привезём кресло куда скажете. <br />У вас будет несколько часов, чтобы испытать эффект <br />от массажа для вас и ваших близких. </p>
                        </div>
                        <div className="description__test_drive_block">
                            <img src={TestDriveMap} alt="" className='animation_active-opacity-scroll' />
                        </div>
                    </div>
                </div>
                <div className="choice__block_test_drive">
                    <h6 className='animation_active-bottom-scroll'>Выберите удобный для вас формат тест-драйва</h6>
                    <div ref={choice} className="choice__test_drive_main">
                        <div ref={choiceMain} className="choice__test_drive__wrapper">
                            <div className="choice__test_drive">
                                <div onClick={() => Scroll(choice)} >Домой или в офис</div>
                                <div onClick={() => Scroll(choice2)}>Наши шоурумы</div>
                                <span >{chText}</span>
                            </div>
                        </div>
                    </div>
                    <div className="choice__main_wrapper">
                        <div className="choice__main_blocks__home">
                            <div className="choice__main_block__home animation_active-opacity-scroll">
                                <h5>Выездной <br /> тест-драйв</h5>
                                <p>дома или в офисе</p>
                                <div className='animation_active-opacity-scroll'>
                                    <img src={TestdriveHouseIcon} alt="" />
                                    <span></span>
                                </div>
                            </div>
                            <div className="choice__main_block__home animation_active-opacity-scroll">
                                <h5 className='animation_active-bottom-scroll'>Новый формат</h5>
                                <p className='animation_active-opacity-scroll'>Мы запустили выездной тест-драйв, чтобы <br />сделать еще один шаг навстречу нашим клиентам. <br />Это позволяет «увидеть, прочуствовать и испытать массаж» в комфортной обстановке.</p>

                                <ul>
                                    <li className='animation_active-bottom-scroll'>
                                        Бесплатная сборка и демонстрация работы
                                    </li>
                                    <li className='animation_active-bottom-scroll'>
                                        Бесплатно доставим в любую точку РФ
                                    </li>
                                    <li className='animation_active-bottom-scroll'>
                                        Попробуйте не выходя из дома
                                    </li>
                                    <li className='animation_active-bottom-scroll'>
                                        Выбирайте удобные дату и время
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="how-work__main_block">
                            <h6 className='animation_active-opacity-scroll'>Как это работает?</h6>
                            <div className="how-work__main_blocks__home-main">

                                <div className="how-work__main_blocks__home animation_active-opacity-scroll">
                                    <div className="how-work__main_blocks__home-top">
                                        <span>1</span>
                                        <h5>Оставьте<br /> заявку</h5>
                                    </div>
                                    <div className="how-work__main_blocks__home-bottom">
                                        <p>Вы оставляете заявку <br />в форме обратной связи <br />или звоните по телефону <br />8-800-700-75-56</p>
                                    </div>
                                </div>
                                <div className="how-work__main_blocks__home animation_active-opacity-scroll">
                                    <div className="how-work__main_blocks__home-top">
                                        <span>2</span>
                                        <h5>Делаете<br /> выбор</h5>
                                    </div>
                                    <div className="how-work__main_blocks__home-bottom">
                                        <p>Выбираете модели кресел для тест-драйва, удобную дату и время, а также место проведения</p>
                                    </div>
                                </div>
                                <div className="how-work__main_blocks__home animation_active-opacity-scroll">
                                    <div className="how-work__main_blocks__home-top">
                                        <span>3</span>
                                        <h5>Доставляем<br /> товары</h5>
                                    </div>
                                    <div className="how-work__main_blocks__home-bottom">
                                        <p>В выбранную вами дату кресла привозят <br />по указанному адресу <br />и производят сборку </p>
                                    </div>
                                </div>
                            </div>

                            <div className="how-work__result">
                                <div className="how-work__result__block">
                                    <h6 className='animation_active-bottom-scroll'>В результате</h6>
                                    <p className='animation_active-bottom-scroll'>Вы и ваши близкие получаете удовольствие от массажа <br />в комфортной обстановке. Кресло, которое вам понравилось <br />вы можете оплатить на месте или оформить в рассрочку</p>
                                </div>
                                <div className="how-work__result__block ">
                                    <img className='animation_active-right-scroll' src={Result} alt="" />
                                </div>
                            </div>
                        </div>


                        <div className="block_test_drive-from">
                            <div className="block_test_drive-from__block">
                                <img src={Form} alt="" />
                            </div>
                            <form className="block_test_drive-from__block"  onsubmit="yaCounter86637692.reachGoal('ORDER'); return true;"  onSubmit={sendEmail}>
                                <h6>Записаться на выездной <br />тест-драйв</h6>
                                <div>
                                    <input className='form-text' type="text" onChange={(e) => setN1Form(e)}  name='name' placeholder='Ваше имя' />
                                    <NumberFormat className='form-text' placeholder='Телефон' format="+7 (###) ###-##-###" onMouseEnter={(e) => e.target.placeholder = '+7 (___) ___-__-___'} onMouseLeave={(e) => e.target.placeholder = 'Телефон'} onChange={(e) => setP1Form(e)} type="text" name='phone' mask="_" />
                                    <div >
                                        <input className="call_back-checkbox" type="checkbox" checked={chekF}  onChange={() => setChekF(!chekF)} name='title' value='Тест-драйв дома или в офисе' />
                                        <p>Я ознакомлен и согласен <a href="./doc/Privacy_Policy.pdf">с условиями политики
                                            обработки персональных данных и конфиденциальности</a></p>
                                    </div>
                                    <button className="form-button" disabled={chekB1} type="button" >Отправить заявку</button>
                                </div>
                            </form>
                        </div>


                        <div ref={choice2} className="choice__main_blocks__home showrooms">
                            <div className="choice__main_block__home animation_active-opacity-scroll">
                                <h5 className='animation_active-bottom-scroll'>Тест-драйв<br /> в шоурумах</h5>
                                <div className='animation_active-opacity-scroll'>
                                    <img src={TestdriveShowroomsIcon} alt="" />
                                    <span></span>
                                </div>
                            </div>
                            <div className="choice__main_block__home animation_active-opacity-scroll">
                                <h5 className='animation_active-opacity-scroll'>Получите собственные<br /> впечатления</h5>
                                <p className='animation_active-bottom-scroll'>
                                    Лучше один раз попробовать и получить собственное впечатление, чем долго изучать информацию в интернете.
                                </p>
                                <p className='animation_active-bottom-scroll'>
                                    Протестируйте кресло FUJIIRYOKI в наших шоурумах по всей России и получите ответы на любые ваши вопросы. Запишитесь на тест-драйв, чтобы лично оценить особенности японского  массажного кресла FUJIIRYOKI.
                                </p>
                            </div>
                        </div>
                        <div className="block_test_drive-from">
                            <div className="block_test_drive-from__block">
                                <img src={Form} alt="" />
                            </div>
                            <form className="block_test_drive-from__block" method="get" onsubmit="yaCounter86637692.reachGoal('ORDER'); return true;" onSubmit={sendEmail}>
                                <h6>Записаться на тест-драйв<br /> в шоуруме</h6>
                                <div>
                                    <input className='form-text' type="text" onChange={(e) => setNForm(e)} name='name' placeholder='Ваше имя' />
                                    <NumberFormat className='form-text' placeholder='Телефон' format="+7 (###) ###-##-###" onMouseEnter={(e) => e.target.placeholder = '+7 (___) ___-__-___'} onMouseLeave={(e) => e.target.placeholder = 'Телефон'} onChange={(e) => setPForm(e)} type="text" name='phone' mask="_" />
                                    <div >
                                        <input className="call_back-checkbox" type="checkbox" checked={chekF}  onChange={() => setChekF(!chekF)} name='title' value='Тест-драйв в шоурумах' />
                                        <p>Я ознакомлен и согласен <a href="./doc/Privacy_Policy.pdf">с условиями политики
                                            обработки персональных данных и конфиденциальности</a></p>
                                    </div>
                                    <button className="form-button"  disabled={chekB} type='submit' >Отправить заявку</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

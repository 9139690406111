
import { NavLink } from 'react-router-dom'
import '../scss/notfound.scss';

export const NotFound = () => {
    return (
        <div className='wrapper__notfound'>
            <h4>404</h4>
            <span className='subtitle'>Страница не найдена</span>
            <p>К сожалению, такой страницы не существует. Возможно, <br />вы перешли <br />по старой ссылке или ввели неправильный адрес. </p>
            <span className='link'>Попробуйте перейти <br />на <NavLink to="/">главную страницу</NavLink> </span>
        </div>
    )
}

import React, { useRef } from "react";
import '../scss/showrooms.scss';

import TestdriveShowroomsIcon from '../img/test-drive/showrooms.svg';

export const Showrooms = () => {

    const allList = useRef();

    const openList = (e) => {

        if (allList !== undefined) {


            if (e.target.nextElementSibling.classList.contains('active')) {
                e.target.nextElementSibling.classList.remove('active');
                e.target.classList.remove('active');
            } else {

                for (let i = 0; i < allList.current.childNodes.length; i++) {
                    for (let j = 0; j < allList.current.childNodes[i].childNodes.length; j++) {
                        allList.current.childNodes[i].childNodes[j].childNodes[0].classList.remove('active');
                        allList.current.childNodes[i].childNodes[j].childNodes[1].classList.remove('active');


                    }
                }
                e.target.nextElementSibling.classList.toggle('active');
                e.target.classList.toggle('active');
            }

        }
    }

    <script type="text/javascript" charset="utf-8" async src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A46af03b653282dad9f2fcd1807efa80aea8152fa7fb98d1816fd87f500150845&amp;width=1170&amp;height=500&amp;lang=ru_RU&amp;scroll=true"></script>

    return (
        <div className="wrapper__showrooms">
            <div className="main__block_showrooms"></div>
            <div className="main_wrapper">

                <div className="choice__main_blocks__home showrooms">
                    <div className="choice__main_block__home">
                        <h5>Адреса наших <br /> шоурумов</h5>
                        <div>
                            <img src={TestdriveShowroomsIcon} alt="" />
                            <span></span>
                        </div>
                    </div>
                    <div className="choice__main_block__home">
                        <h5>Наши шоурумы<br /> в Москве:</h5>
                        <h6>МЕБЕЛЬ PARK</h6>
                        <p>г. Москва. Киевское шоссе, 22-й км, дв4, с1. (Блок А, вход 2,этаж 1). БП Румянцево</p>
                        <span>Пн-Вс: с 10:00 до 21:00</span>

                        <h6>ТРЦ МЕГА Белая Дача, Салон ERGONOVA</h6>
                        <p>Метро Кузьминки | г. Котельники, <br />1-й Покровский пр-д, 5 напротив кассовой зоны ИКЕА</p>
                        <span>Пн-Пт: с 10:00 до 23:00, <br /> Сб-Вс: с 10:00 до 00:00</span>

                        <h6>Контактный телефон:</h6>
                        <a href="tel:8 (800) 600-98-58 ">8 (800) 600-98-58 </a>
                    </div>
                </div>

                <div className="main__block_showrooms__city">
                    <h5>Шоурумы наших <br />дилеров в других городах</h5>
                    <div ref={allList} className="showrooms__city__blocks">
                        <div>
                            <div>
                                <span onClick={(e) => openList(e)} >Самара</span>
                                <div className="showrooms__city__block">
                                    <span className="city1">Адреса наших салонов в Самаре:</span>
                                    <span className="city2">МЕГА Самара, остров ERGONOVA Московское шоссе, 24-й километр, 5</span>
                                    <span className="city3">1 этаж, рядом с М.Видео</span>
                                    <span className="city4">с 10 до 22, ежедневно</span>
                                    <span className="city2">МЕГА Самара, Салон ERGONOVA Московское шоссе, 24-й километр, 5</span>
                                    <span className="city3">1 этаж, по стороне гипермаркета Ашан, рядом с Аптекой</span>
                                    <span className="city4">с 10 до 22, ежедневно</span>
                                    <span className="city2">ТРК КОСМОПОРТ, остров ERGONOVA ул. Дыбенко, 30</span>
                                    <span className="city3">1 этаж</span>
                                    <span className="city4">с 10:00 до 23:00, ежедневно</span>
                                </div>
                            </div>
                            <div>
                                <span onClick={(e) => openList(e)}>Уфа</span>
                                <div className="showrooms__city__block">
                                    <span className="city1">Адреса наших салонов в Уфе:</span>
                                    <span className="city2">МЕГА Уфа, Салон ERGONOVA ул. Рубежная, 174</span>
                                    <span className="city4">с 10 до 22, ежедневно</span>
                                    <span className="city2">МЕГА Уфа, остров ERGONOVA ул. Рубежная, 174</span>
                                    <span className="city4">с 10 до 22, ежедневно</span>
                                </div>
                            </div>
                            <div>
                                <span onClick={(e) => openList(e)}>Тюмень</span>
                                <div className="showrooms__city__block">
                                    <span className="city1">Адреса наших салонов в Тюмени:</span>
                                    <span className="city2">ТК «ОРИОН» Салон ERGONOVA ул. Федюнинского, 43, 1 уровень</span>
                                    <span className="city3">рядом с магазином «Амега»</span>
                                    <span className="city4">ежедневно, 10:00–21:00</span>
                                    <span className="city2">ТРЦ «Премьер» ул. 50 лет ВЛКСМ, д. 63</span>
                                    <span className="city3">(вход со стороны АЗС)</span>
                                    <span className="city4">ежедневно, 10:00–21:00</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span onClick={(e) => openList(e)}>Санкт-Петербург</span>
                                <div className="showrooms__city__block">
                                    <span className="city1">Адреса наших салонов в Санкт-Петербурге:</span>
                                    <span className="city2">ТРЦ Мега Парнас, Салон ERGONOVA Проспект Энгельса / КАД внешнее кольцо 117 километр, 1, 1 этаж</span>
                                    <span className="city3">Вход Икея, прямо по коридору до конца, справа</span>
                                    <span className="city4">ежедневно, 10:00–22:00</span>
                                </div>
                            </div>
                            <div>
                                <span onClick={(e) => openList(e)}>Челябинск</span>
                                <div className="showrooms__city__block">
                                    <span className="city1">Адреса наших салонов в Челябинске:</span>
                                    <span className="city2">ТРК Родник, Салон ERGONOVA Ул. Труда, 203</span>
                                    <span className="city3">(–2 этаж), напротив Castorama</span>
                                    <span className="city4">c 10 до 22, ежедневно</span>
                                    <span className="city2">ТРЦ Алмаз, Салон ERGONOVA Копейское шоссе, 64</span>
                                    <span className="city3">2 этаж, рядом с Leroy Merlin</span>
                                    <span className="city4">с 10 до 22, ежедневно</span>
                                    <span className="city2">ТРК Космос, Салон ERGONOVA Ул. Чичерина, 28</span>
                                    <span className="city3">с 10 до 22, ежедневно</span>
                                    <span className="city4">8 (800) 600-98-58</span>
                                </div>
                            </div>
                            <div>
                                <span onClick={(e) => openList(e)}>Екатеринбург</span>
                                <div className="showrooms__city__block">
                                    <span className="city1">Адреса наших салонов в Екатеринбурге:</span>
                                    <span className="city2">РЦ РАДУГА ПАРК, Салон ERGONOVA Ул. Репина, 94</span>
                                    <span className="city3">напротив Yota</span>
                                    <span className="city4">с 10 до 22, ежедневно</span>
                                    <span className="city2">ТЦ ДирижабльАк. Шварца</span>
                                    <span className="city4">ежедневно, 10:00–22:00</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span onClick={(e) => openList(e)}>Сургут</span>
                                <div className="showrooms__city__block">
                                    <span className="city1">Адреса наших салонов в Сургуте:</span>
                                    <span className="city2">ТРЦ АУРА, Салон ERGONOVA Нефтеюганское шоссе, 1</span>
                                    <span className="city3">Центральный вход, 2-ой этаж, по левой стороне от Спортмастера</span>
                                    <span className="city4">ежедневно, 10:00–22:00</span>
                                </div>
                            </div>
                            <div>
                                <span onClick={(e) => openList(e)}>Магнитогорск</span>
                                <div className="showrooms__city__block">
                                    <span className="city1">Адреса наших салонов в Магнитогорске:</span>
                                    <span className="city2">ТРК Гостиный Двор, Салон ERGONOVA г. Магнитогорск, проспект Карла Маркса, 153</span>
                                    <span className="city3">1 этаж</span>
                                    <span className="city4">с 10 до 21, ежедневно</span>
                                </div>
                            </div>
                            <div>
                                <span onClick={(e) => openList(e)}>Оренбург</span>
                                <div className="showrooms__city__block">
                                    <span className="city1">Адреса наших салонов в Оренбурге:</span>
                                    <span className="city2">Молл Армада, остров ERGONOVA Шарлыкское шоссе, 1</span>
                                    <span className="city3">Молл Армада, галерея 1, 1 этаж</span>
                                    <span className="city4">с 10 до 22, ежедневно</span>
                                    <span className="city2">ТРЦ ARMADA-2, Салон ERGONOVA Нежинское шоссе, 2А</span>
                                    <span className="city3">1 этаж, рядом с магазином «Лента»</span>
                                    <span className="city4">с 10 до 22, ежедневно</span>
                                    <span className="city2">Молл Армада, Салон ERGONOVAШарлыкское шоссе, 1</span>
                                    <span className="city3">Молл Армада, галерея Лувр, 1 этаж</span>
                                    <span className="city4">с 10 до 22, ежедневно</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="map">
                <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Aaf5a80e9a6d6cfde2c600e48460e5601e8c7235488eb1d21803994b9f62bddc1&amp;source=constructor" width="100%" height="100%" frameborder="0"></iframe>
                </div>
            </div>
        </div>

    )
}

import React from 'react'
import '../scss/thanks.scss';

import Done from '../img/done.svg';

export const Thanks = () => {

    return (
        <div className='wrapper__thanks'>
            <img src={Done} alt="" />
            <h2>Спасибо, <br/> ваша заявка принята!</h2>
            <p>Наши менеджеры свяжутся с вами <br/>в ближайшее время</p>
        </div>
    )
}

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com';
import NumberFormat from 'react-number-format';
export const Modal = (props) => {

    let navigate = useNavigate();
    const [chekF, setChekF] = useState(true)
    const [chekB, setChekB] = useState();


    function sendEmail(e) {
        e.preventDefault();
        console.log(e.target)
        emailjs.sendForm('service_8p7tccp', 'template_q9nvppd', e.target, 'user_h8FptbxZL1IEIHpQZnLeu')
        props.onModalClose()
        navigate("/thanks");
        // window.location.href = '/thanks';
    }

    const ref = useRef()
    useEffect(() => {
        ref.current.classList.remove('hide')
    }, [])



    const [nFrom, setNForm] = useState();
    const [pFrom, setPForm] = useState();

    useEffect(() => {
        if (chekF === true && nFrom?.target.value.length > 2 && pFrom?.target.value.split('_').join('').split('-').join('').split(' ').join('').length > 13) {
            setChekB(false)
        } else {
            setChekB(true)
        }
    }, [nFrom, pFrom, chekF])

    return (
        <>
            <div ref={ref} className='modal__wrapper hide'>
                <div className="modal__block">
                    <div onClick={props.onModalClose} className="close_modal">
                    </div>
                    <h4 className={props.title.length > 26 ? 'big-title' : ''}> {props.title === 'Заказать Fujiiryoki' ? <> Заказать  <span>Fujiiryoki</span> </> : props.title}</h4>
                    <p> {props.desc === 'modal1' ? <>Свяжемся с вами в течение 15 минут. <br />Наш менеджер ответит на все ваши вопросы.</> : props.desc === 'modal2' ? <>Оставьте свои данные для связи. <br />Наши менеджеры решат все ваши вопросы. </> : props.desc === 'modal4' ? <>Оставьте свои данные для связи. <br />Наши менеджеры сделают вам индивидуальное предложение.</> : props.desc === 'modal5' ? <>Оставьте свои данные для связи<br /> и мы привезем кресло куда скажете</> : <>Свяжемся с вами в течение 15 минут. <br />Наш менеджер ответит на все ваши вопросы.</>}</p>


                    <form className="call_back-form" method="get" type="submit" onsubmit="yaCounter86637692.reachGoal('ORDER'); return true;" onSubmit={sendEmail} >
                        <input className='call_back-input' onChange={(e) => setNForm(e)} type="text" name='name' placeholder='Ваше имя' />
                        <NumberFormat className='call_back-input' placeholder='Телефон' format="+7 (###) ###-##-###" onMouseEnter={(e) => e.target.placeholder = '+7 (___) ___-__-___'} onMouseLeave={(e) => e.target.placeholder = 'Телефон'} onChange={(e) => setPForm(e)} type="text" name='phone' mask="_" />
                        <div >
                            <input className="call_back-checkbox" type="checkbox" checked={chekF} onChange={() => setChekF(!chekF)} name='title' value={props.etitle} />
                            <p>Я ознакомлен и согласен <a href="../doc/Privacy_Policy.pdf">с условиями политики
                                обработки персональных данных и конфиденциальности</a></p>
                        </div>
                        <button className="call_back-button"  disabled={chekB} type='submit' >Отправить заявку</button>
                    </form>
                </div>
            </div>
        </>
    )
}

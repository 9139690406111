import React, { useEffect } from "react";
import '../scss/delivery.scss';

import TestDriveMap from '../img/test-drive/testdrive-map.png';

export const Delivery = () => {

    /* Анимация */
    useEffect(() => {
        readyHandler()
    }, [])

    const readyHandler = () => {
        const animation_active_opacity = document.querySelectorAll('.animation_active-opacity');

        setTimeout(() => {
            for (let i = 0; i < animation_active_opacity.length; i++) {
                animation_active_opacity[i].classList.remove('animation_active-opacity');
            }

        }, 500);
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return function () {
            document.removeEventListener('scroll', scrollHandler)
        };
    }, [])

    const scrollHandler = () => {
        setTimeout(() => {
            const arrAnimation_active_bottom = document.querySelectorAll('.animation_active-bottom-scroll');
            const arrAnimation_active_opacity = document.querySelectorAll('.animation_active-opacity-scroll');

            for (let i = 0; i < arrAnimation_active_bottom.length; i++) {
                var elementBoundary = arrAnimation_active_bottom[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_bottom[i].classList.remove('animation_active-bottom-scroll');
                }
            }

            for (let i = 0; i < arrAnimation_active_opacity.length; i++) {
                var elementBoundary = arrAnimation_active_opacity[i].getBoundingClientRect();
                var top = elementBoundary.top;
                var width = elementBoundary.width;
                if (window.innerHeight - (top + (width / 2)) > 0) {
                    arrAnimation_active_opacity[i].classList.remove('animation_active-opacity-scroll');
                }
            }
        });
    }

    return (
        <div className="wrapper__delivery">
            <div className="main__block_delivery animation_active-opacity"></div>
            <div className="main_wrapper">
            <div className="delivery__blocks">
                <div className="delivery__block">
                    <img src={TestDriveMap} className='animation_active-opacity-scroll' alt="" />
                </div>

                    <div className="delivery__block">
                        <h5 className='animation_active-bottom-scroll'>Бесплатная <br/>доставка по РФ</h5>
                        <ul>
                            <li  className='animation_active-bottom-scroll'>Собственная служба логистики</li>
                            <li  className='animation_active-bottom-scroll'>Обязательный звонок курьера за час <br/>до доставки товара</li>
                            <li  className='animation_active-bottom-scroll'>Доставка кресла до указанного вами места <br/>в квартире или офисе независимо от этажа</li>
                            <li  className='animation_active-bottom-scroll'>Распаковка и демонстрация товара <br/>в действии</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
